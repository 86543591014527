import { graphql } from "gatsby"
import React from "react"
import {
  BlogPostFooter,
  BlogPostHeader,
  LetsTalk,
  RelatedBlogPosts,
  Section,
  SEO,
  PressLinks,
} from "../components"
import { BlogPost } from "../models/BlogPost"
import { Page } from "../models/Page"

interface Props {
  data: {
    post: BlogPost
  }
}

const BlogPostPage = (props: Props & Page) => {
  const { post } = props.data

  return (
    <>
      <SEO seoMetaTags={post.seoMetaTags} />
      <article>
        <BlogPostHeader
          author={post.author}
          publicationDate={post.publicationDate}
          tags={post.tags}
          title={post.title}
        />

        {post.content &&
          post.content.map((section, i) => {
            return <Section isBlog={true} key={section.id} section={section} order={i} />
          })}

        {post.pressLinks && post.pressLinks.length > 0 && (
          <div className="pb-16 lg:pb-20">
            <PressLinks isBlog={true} links={post.pressLinks} />
          </div>
        )}
      </article>

      <BlogPostFooter
        author={post.author}
        publicationDate={post.publicationDate}
        slug={post.slug}
        title={post.title}
      />

      <RelatedBlogPosts posts={post.related} />

      <LetsTalk />
    </>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!, $locale: String!) {
    post: datoCmsBlogPost(slug: { eq: $slug }, locale: { eq: $locale }) {
      author
      publicationDate
      slug
      tags
      title
      content {
        __typename
        ...AnimatedGifFragment
        ...HeroGalleryFragment
        ...ImageFragment
        ...ImageGalleryLargeFragment
        ...QuoteFragment
        ...TextFragment
        ...VideoFragment
        ...Text2colFragment
      }
      pressLinks {
        ...PressLinkFragment
      }
      related {
        id
        tags
        title
        slug
        thumbnail {
          gatsbyImageData(width: 616, imgixParams: { auto: "format" })
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default BlogPostPage
